const React = require("react");
require("bootstrap/dist/css/bootstrap.min.css");
require("./src/styles/index.scss");
require('lightgallery/css/lightgallery.css');
require('lightgallery/css/lg-zoom.css');
require('lightgallery/css/lg-thumbnail.css');
require('lightgallery/scss/lightgallery.scss');
require('lightgallery/scss/lg-zoom.scss');

exports.wrapPageElement = ({ element, props }: any) => {
  return (
      <section {...props}>{element}</section>
  );
};

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"30daaa0ea2e076836ea93094cda3e06b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://admin.bckoneser.pl/graphql","headers":{"Authorization":"Bearer 3e06db2a40465278e40d8e3c473e3c75e64ff497d9f00288c0a7672b75bc5fd3572aa677d8a6863d0abf963e5c9d193e62b21c530060a3beac4a2d07b2dee48085a8a04d9bbca7d833298c1b68fc7cd35063ebdee2cb0d1fbb909d6ed7e5a32f8c52d96980fc65c63e72228d4d83b7ef6a549397b5716d41c214c59476952423"}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
